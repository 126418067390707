#scrollable-content {
    height: 55vh; /* Adjust the height of the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 5px;
    overflow-x: hidden;
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
#scrollable-content::-webkit-scrollbar {
    width: 12px;
}

#scrollable-content::-webkit-scrollbar-track {
    background: #ffffff;
    /* White background for the track */
}

#scrollable-content::-webkit-scrollbar-thumb {
    background-color: #0a0a64;
    /* Dark blue color for the thumb */
    border-radius: 9px;
    border: 3px solid #ffffff;
    /* Adds padding around the thumb */
    height: 5px;
}

#scrollable-content::-webkit-scrollbar-thumb:hover {
    background-color: #ffc107;
    /* Yellow color on hover */
}

.logo-img-provider {
    width: 100%;
    height: 150px;
    object-fit: contain;
    padding-bottom: 10px;
}

@media (max-width: 768px) {
    .logo-img-provider {
        height: 90px;
    }
}
  