body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #030765 !important;
  height: 100%;
}

.card {
  color: #030765 !important;
}

.btn {
  color: #030765 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure the root (where your app is rendered) takes full height */
}

main {
  flex: 1; /* This makes the main content area grow to take up any available space */
}

@media (max-width: 576px) {
  .pagination {
    padding: 0.5rem;
  }
  .pagination .page-link {
    margin: 0 2px;
    font-size: 0.65rem;
  }
}

.iframe-pages {
  width: 100%; 
  height: 75vh;
  overflow: hidden;
}

@media (max-width: 1920px) {
  .iframe-pages {
    height: 67vh;
  }
}