.header-background {
    background-color: #030765;
}
  
.logo img{
    width:17%
}

@media (max-width: 1920px) {
    .logo img{
        width:27%
    }
}

@media (max-width: 768px) {
    .logo img{
        width:70%
    }
}