/* Custom arrow for the previous button */
.carousel-control-prev-icon {
    background-image: none;
    border: solid 2px transparent;
    border-left-color: #030765; /* Custom color for the arrow */
    border-top-color: #030765;
    width: 30px;
    height: 30px;
    transform: rotate(-45deg); /* Make it point left */
  }
  
  /* Custom arrow for the next button */
  .carousel-control-next-icon {
    background-image: none;
    border: solid 2px transparent;
    border-right-color: #030765; /* Custom color for the arrow */
    border-top-color: #030765;
    width: 30px;
    height: 30px;
    transform: rotate(45deg); /* Make it point right */
  }
  
  /* Hide carousel arrows on mobile devices */
  @media (max-width: 768px) {
    .carousel-control-prev,
    .carousel-control-next {
      display: none !important;
    }
    .news-carousel {
      height: 550px !important;
    }
  }
  
  .news-carousel {
    background-color: #030765 !important;
    border-radius: 15px !important;
    height: 233px;
  }

  .news-img {
    object-fit: cover;
  }

  .news-img img:not([src$=".svg"]) {
    width: 90%;
  }