#htmlContentMenuBox {
    max-height: 70vh;
    overflow: auto;
    margin-bottom: 25px;
}

/* Customize scrollbar for WebKit browsers (Chrome, Safari, Edge) */
#htmlContentMenuBox::-webkit-scrollbar {
    width: 12px;
}

#htmlContentMenuBox::-webkit-scrollbar-track {
    background: #ffffff;
    /* White background for the track */
}

#htmlContentMenuBox::-webkit-scrollbar-thumb {
    background-color: #0a0a64;
    /* Dark blue color for the thumb */
    border-radius: 9px;
    border: 3px solid #ffffff;
    /* Adds padding around the thumb */
    height: 5px;
}

#htmlContentMenuBox::-webkit-scrollbar-thumb:hover {
    background-color: #ffc107;
    /* Yellow color on hover */
}

@media (max-width: 768px) {
    #htmlContentMenuBox {
        max-height: 300px;
        overflow-y: auto;
    }
}