footer {
  background-color: #f8f9fa; /* Light background */
  padding: 20px 0;
  margin-top: auto; /* Push the footer to the bottom */
}
  
footer p, footer a {
  margin-bottom: 1rem; /* Spacing between text and links */
}

footer .list-inline-item a {
  padding: 0 10px; /* Adds spacing between links */
  text-decoration: none;
  color: #5a5c5e; /* Muted link color */
}

footer .list-inline-item a:hover {
  text-decoration: underline;
  color: #0d6efd; /* Primary color on hover */
}

footer .link-responsive {
  text-decoration: none; /* No underline by default */
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition */
}

footer .link-responsive:hover {
  text-decoration: underline; /* Underline on hover */
  color: #0d6efd; /* Color change on hover */
}